import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from '../img/tasaciones_logo.png'; // Update with your logo path
import { FaUserCircle, FaBars, FaTimes, FaShoppingCart, FaSignOutAlt, FaCoins } from 'react-icons/fa'; // Import icons for user, hamburger menu, shopping cart, sign-out, and coins

const primaryColor = '#8360C3'; // Purple color for hamburger icon and active states
const secondaryColor = '#2EBF91'; // Green color for menu items

const navbarStyle = {
  backgroundColor: 'white',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const textFieldStyle = {
  fontSize: '20px', // Increased font size
  display: 'flex',
  alignItems: 'center',
  padding: '10px', // Added padding for better spacing
};

const hamburgerMenuStyle = {
  fontSize: '24px',
  cursor: 'pointer',
  color: primaryColor, // Set color to match the tasacionesLetter image
};

const menuOpenStyle = {
  position: 'absolute',
  top: '60px',
  right: '0', // Adjusted to ensure it is flush to the right
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '10px',
  borderRadius: '4px',
  zIndex: '1000',
  width: '200px',
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  textDecoration: 'none',
  color: secondaryColor, // Use secondary color for menu items
};

const menuIconStyle = {
  marginRight: '8px', // Space between icon and text
};

const logoStyle = {
  width: '120px', // Adjust logo size as needed
  height: 'auto',
};

const logoStyleMobile = {
  width: '80px', // Smaller logo for mobile
  height: 'auto',
};

const creditsIconStyle = {
  fontSize: '24px', // Increased icon size
  marginRight: '12px', // Increased space between icon and text
  color: secondaryColor,
};

const desktopNavStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
};

const mobileNavStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const CustomNavbar = ({ isAuthenticated, handleLogout, username, amount, onBuyCredits }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Navbar bg='light' variant='light' expand='lg' style={navbarStyle}>
      <Container fluid>
        {/* Desktop View */}
        {!isMobile && (
          <div style={desktopNavStyle}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', paddingLeft: '30px' }}>
              <div style={textFieldStyle}>
                <FaCoins style={creditsIconStyle} />
                {amount}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
              <Navbar.Brand as={Link} to='/'>
                <img src={logo} alt='Logo' style={logoStyle} />
              </Navbar.Brand>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'flex-end', paddingRight: '30px' }}>
              {isAuthenticated && (
                <NavDropdown
                  title={<><FaUserCircle style={{ fontSize: '24px', marginRight: '8px' }} /> {username}</>}
                  id='user-dropdown'
                  align='end' // Align dropdown to the end (right side)
                >
                  <NavDropdown.Item onClick={onBuyCredits} style={{ color: secondaryColor }}>
                    <FaShoppingCart style={menuIconStyle} />
                    Comprar créditos
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout} style={{ color: secondaryColor }}>
                    <FaSignOutAlt style={menuIconStyle} />
                    Cerrar sesión
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                onClick={toggleMenu}
                style={hamburgerMenuStyle} // Ensure it's pushed all the way to the right
              >
                {isMenuOpen ? <FaTimes /> : <FaBars />}
              </Navbar.Toggle>
            </div>
          </div>
        )}
        {/* Mobile View */}
        {isMobile && (
          <div style={mobileNavStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Navbar.Brand as={Link} to='/'>
                <img src={logo} alt='Logo' style={logoStyleMobile} />
              </Navbar.Brand>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={textFieldStyle}>
                <FaCoins style={creditsIconStyle} />
                {amount}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                onClick={toggleMenu}
                style={hamburgerMenuStyle}
              >
                {isMenuOpen ? <FaTimes /> : <FaBars />}
              </Navbar.Toggle>
            </div>
          </div>
        )}
        {/* Mobile View - Menu */}
        <Navbar.Collapse id='basic-navbar-nav'>
          {isMobile && isMenuOpen && (
            <div style={menuOpenStyle} className="d-lg-none">
              {isAuthenticated ? (
                <>
                  <div onClick={onBuyCredits} style={menuItemStyle}>
                    <FaShoppingCart style={menuIconStyle} />
                    Comprar créditos
                  </div>
                  <div onClick={handleLogout} style={menuItemStyle}>
                    <FaSignOutAlt style={menuIconStyle} />
                    Cerrar sesión
                  </div>
                </>
              ) : (
                <Link to='/login' style={menuItemStyle}>Login</Link>
              )}
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
