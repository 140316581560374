import React from 'react';

// Define styles for the modal, including the promotion message
const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker overlay for better contrast
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  content: {
    backgroundColor: '#fefefe',
    padding: '30px',
    borderRadius: '12px',
    position: 'relative',
    maxWidth: '700px',
    width: '90%',
    maxHeight: '85vh',
    overflowY: 'auto',
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.4)',
    animation: 'fadeIn 0.3s ease-out',
    border: '2px solid #007bff', // Colorful border
  },
  close: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    fontSize: '30px',
    cursor: 'pointer',
    color: '#ff5722', // Vibrant close button color
    transition: 'color 0.3s',
  },
  closeHover: {
    color: '#e91e63', // Hover color
  },
  title: {
    fontSize: '28px',
    marginBottom: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#007bff', // Vibrant title color
  },
  servicesList: {
    fontSize: '18px',
    margin: '20px 0',
    textAlign: 'center',
    color: '#333', // Darker text color for better readability
    lineHeight: '1.6',
  },
  listItem: {
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#e3f2fd', // Light blue background for list items
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0277bd', // Text color for list items
  },
  listIcon: {
    marginRight: '10px',
    width: '28px',
    height: '28px',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '14px 20px',
    marginTop: '20px',
    backgroundColor: '#ff5722', // Vibrant button color
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '18px',
    transition: 'background-color 0.3s, transform 0.3s',
    fontWeight: 'bold',
  },
  buttonHover: {
    backgroundColor: '#e64a19', // Darker shade for button hover
    transform: 'scale(1.05)',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
};

const svgIcons = {
  instantValuation: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="bi bi-clock"
      style={modalStyles.listIcon}
    >
      <path d="M12 2a10 10 0 1 1-10 10A10.011 10.011 0 0 1 12 2zm0 18a8 8 0 1 0-8-8 8.009 8.009 0 0 0 8 8zm-1-13a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0 6a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1z"/>
    </svg>
  ),
  pdfReport: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="bi bi-file-earmark-pdf"
      style={modalStyles.listIcon}
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM6 0a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8h-6a2 2 0 0 1-2-2V0H6z"/>
      <path d="M8.5 14.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-3zm.5 2v-1h1v1h-1zm4 0v-1h1v1h-1zm-4-3v-1h2v1h-2zm4 1h-1v-1h1v1z"/>
    </svg>
  ),
};

const SampleResultModal = ({ isOpen, onClose, onRegister }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleRegisterClick = () => {
    if (onRegister) {
      onRegister(true); // Call onRegister with true if needed
    }
    onClose(); // Close the modal after calling onRegister
  };

  return (
    <div style={modalStyles.overlay} onClick={handleOverlayClick}>
      <div style={modalStyles.content} onClick={(e) => e.stopPropagation()}>
        <span 
          style={modalStyles.close} 
          onClick={onClose}
          onMouseEnter={(e) => e.target.style.color = modalStyles.closeHover.color}
          onMouseLeave={(e) => e.target.style.color = '#ff5722'}
        >
          &times;
        </span>
        <div style={modalStyles.title}>
          ¿Qué incluye la tasación?
        </div>
        <div style={modalStyles.servicesList}>
          <div style={modalStyles.listItem}>
            <span style={modalStyles.listIcon}>{svgIcons.instantValuation}</span>
            Tasación al instante
          </div>
          <div style={modalStyles.listItem}>
            <span style={modalStyles.listIcon}>{svgIcons.pdfReport}</span>
            Reporte en PDF
          </div>
        </div>
        <button 
          style={modalStyles.button}
          onMouseEnter={(e) => e.target.style.backgroundColor = modalStyles.buttonHover.backgroundColor}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#ff5722'}
          onClick={handleRegisterClick}
        >
          Registrarse para continuar
        </button>
      </div>
    </div>
  );
};

export default SampleResultModal;
