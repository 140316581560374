import React from 'react';

const PaymentFailed = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/541151048360', '_blank');
  };

  return (
    <div style={styles.container}>
      <img
        src="https://tasacionesya.com/img/inmoapp_logo_2.png"
        alt="Tasaciones Logo"
        style={styles.logo}
      />
      <h2 style={styles.title}>¡No fue posible procesar el pago!</h2>
      <p style={styles.message}>
        Si tuviste un problema con el cobro, no te preocupes, envíanos un mensaje a <span style={styles.link} onClick={handleWhatsAppClick}>+541151048360</span> y te enviamos el link de pago.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '12px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  logo: {
    maxWidth: '150px',
    height: 'auto',
    marginBottom: '20px',
  },
  title: {
    color: '#E53935',
    fontSize: '2rem',
    fontWeight: '700',
    marginBottom: '20px',
  },
  message: {
    color: '#555',
    fontSize: '1.2rem',
    marginBottom: '20px',
  },
  link: {
    color: '#1EBEA5',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default PaymentFailed;
