import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../css/ModalCredits.css'; // Import your custom CSS if you have any
import axios from 'axios';
import ModalCredits from './ModalCredits'; // Import ModalCredits component

// Define your styles as a constant
const stylesForm3 = `
  .form-label-custom {
    color: #8360C3; /* Change this color to whatever you prefer */
  }
`;

const ModalLogin = ({ status, setModalLogin, url, username, password }) => {
    const [formData, setFormData] = useState({
        email: username || '',
        password: password || ''
    });
    const [loading, setLoading] = useState(false); // State to handle loading
    const [error, setError] = useState(null); // State to handle errors
    const [success, setSuccess] = useState(false); // State to handle success message
    const [showModalCredits, setShowModalCredits] = useState(false); // State to handle ModalCredits visibility

    const navigate = useNavigate(); // Initialize useNavigate

    // Update formData when username or password props change
    useEffect(() => {
        if (username && password) {
            setFormData({ email: username, password: password });
            handleSubmit(); // Automatically submit the form
        }
    }, [username, password]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault(); // Prevent default if e is provided
        if (!formData.email || !formData.password) return; // Return early if email or password is missing
        
        setLoading(true); // Set loading to true when form is submitted
        setError(null); // Clear previous errors
        setSuccess(false); // Clear previous success message

        try {
            // Replace this URL with your actual API endpoint for login
            const response = await axios.post(`https://tasacionesya.com:8099/api/login`, formData);
        
            // Handle the response if needed
        
            if (response.data.token) {
                // Successful login
                setSuccess(true); // Set success message state
                // Optionally store the token in local storage or context
                localStorage.setItem('userToken', response.data.token);
            } else {
                // Handle non-200 responses
                setError('Credenciales no válidas.');
            }
        } catch (err) {
            // Handle errors
            console.error('Error submitting form:', err);
            setError('Ocurrió un error. Por favor, pruebe nuevamente.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    useEffect(() => {
        if (success) {
            navigate('/vendor'); // Redirect to /vendor if login is successful
        }
    }, [success, navigate]);

    const handleClose = () => {
        setModalLogin(false);
        setSuccess(false); // Reset success state when closing the modal
    };

    const openModalCredits = () => {
        setShowModalCredits(true); // Open the ModalCredits modal
        handleClose(); // Close the ModalLogin modal
    };

    return (
        <>
            <Modal show={status} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {success ? 'Login Successful' : 'Ingresar'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {success ? (
                        <p>Welcome back! You are now logged in.</p>
                    ) : (
                        <>
                            <style>{stylesForm3}</style> {/* Apply custom styles */}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label className="form-label-custom">Correo Electronico</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Ingrese su correo electronico"
                                        name="email"
                                        value={formData.email} // Controlled value
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword">
                                    <Form.Label className="form-label-custom">Clave</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Ingrese su clave"
                                        name="password"
                                        value={formData.password} // Controlled value
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                {error && <p className="text-danger">{error}</p>} {/* Display error message */}

                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={loading} // Disable button while loading
                                    className="w-100" // Full-width button
                                >
                                    {loading ? (
                                        <Spinner 
                                            animation="border" 
                                            size="sm" 
                                            role="status" 
                                            aria-hidden="true" 
                                            className="me-2"
                                        />
                                    ) : (
                                        'Ingresar'
                                    )}
                                    {loading ? ' Verificando...' : ''}
                                </Button>
                            </Form>
                            <div className="text-center mt-3">
                                <Button 
                                    variant="link" 
                                    onClick={openModalCredits}
                                >
                                    Registrarse
                                </Button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            {/* ModalCredits Component */}
            <ModalCredits 
                status={showModalCredits} 
                setModalC={setShowModalCredits} // Pass setShowModalCredits correctly
                url={url} // Ensure URL is passed if needed
            />
        </>
    );
};

export default ModalLogin;
