import axios from 'axios';

export const verifyToken = async () => {
  const token = localStorage.getItem('userToken');
  if (!token) return false;

  try {
    // Send the token in the Authorization header as Bearer token
    const response = await axios.post(
      'https://tasacionesya.com:8099/api/validate',
      {}, // No need to send body content
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Check the server response
    if (response.status === 200 && response.data.valid) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Token validation failed:', error);
    return false;
  }
};
