import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { FaCheckCircle, FaEnvelope, FaMapMarkerAlt, FaFilePdf } from 'react-icons/fa'; // Icons for services

// Define your styles as a constant
const stylesForm = `
  .form-label-custom {
    color: #8360C3; /* Change this color to whatever you prefer */
  }
  .form-control-error {
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }
  .items-section {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  .items-section p {
    font-size: 16px;
    margin: 10px 0;
  }
  .items-section svg {
    margin-right: 8px;
    color: #8360C3; /* Color for icons */
  }
  .items-section .item-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .price {
    font-size: 18px;
    font-weight: bold;
    color: #8360C3; /* Price color */
    margin-bottom: 20px;
  }
`;

const ServiceModal = ({ status, setModalC, url, found }) => {
  const [formData, setFormData] = useState({
    correo: '', nombre: '', apellido: '', telefono: '', tipoUsuario: ''
  });
  const [loading, setLoading] = useState(false); // State to handle loading
  const [error, setError] = useState({}); // State to handle errors
  const [success, setSuccess] = useState(false); // State to handle success message
  const [termsAccepted, setTermsAccepted] = useState(false); // State to handle terms acceptance
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Set a timeout to show the modal after 5 seconds
    const timer = setTimeout(() => {
      setShowModal(false);
    }, 5000);

    // Clear the timeout if the component is unmounted before the timeout completes
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Clear error for that specific field
    setError({
      ...error,
      [name]: ''
    });
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted
    setError({}); // Clear previous errors
    setSuccess(false); // Clear previous success message

    let validationErrors = {};
    if (!formData.correo) {
      validationErrors.correo = 'El correo electrónico es obligatorio.';
    }
    if (!termsAccepted) {
      validationErrors.terms = 'Debes aceptar los términos y condiciones.';
    }
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      setLoading(false);
      return;
    }

    try {
      // Replace this URL with your actual API endpoint
      const response = await axios.post(`${url}:8099/api/register`, formData);

      // Handle the response if needed
      console.log('Form Data Submitted:', response.data);

      if (response.data === 'User created') {
        // Successful submission
        setSuccess(true); // Set success message state
      } else if (response.data === 'Usuario ya registrado') {
        // User already exists
        setError({ correo: 'Usuario ya registrado. Por favor, utilice otro correo electrónico.' });
      } else {
        // Handle other non-200 responses
        setError({ general: 'Hubo un problema al enviar el formulario. Por favor, intente de nuevo.' });
      }
    } catch (err) {
      // Handle errors
      console.error('Error submitting form:', err);
      setError({ general: 'Hubo un problema al enviar el formulario. Por favor, intente de nuevo.' });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleClose = () => {
    setSuccess(false); // Reset success state when closing the modal
    setShowModal(false); // Close the modal
  };

  return (
    <Modal show={showModal && status} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <p>Le hemos enviado un correo electrónico para ingresar en su cuenta. Si no lo recibió, por favor revise la casilla de correo no deseado. Gracias.</p>
        ) : (
          <>
            <div className="price">Precio: $3.500</div>
            <style>{stylesForm}</style> {/* Apply custom styles */}
            
            <div className="items-section">
              <p className="item-title">Servicios Ofrecidos</p>
              <p><FaCheckCircle /> Tu valuación en tiempo real</p>
              <p><FaMapMarkerAlt /> Si no cubrimos la zona, uno de nuestros martilleros te enviará la valuación hecha manualmente a tu correo</p>
              <p><FaFilePdf /> Reporte en PDF para descargar</p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formCorreo">
                <Form.Label className="form-label-custom">Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Introduce tu correo electrónico"
                  name="correo"
                  value={formData.correo}
                  onChange={handleChange}
                  className={error.correo ? 'form-control-error' : ''}
                  required
                />
                {error.correo && <Form.Text className="text-danger">{error.correo}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formTerms">
                <Form.Check
                  type="checkbox"
                  label={<span>Al continuar, acepta los <a href="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">términos y condiciones</a></span>}
                  checked={termsAccepted}
                  onChange={handleCheckboxChange}
                  className={error.terms ? 'form-control-error' : ''}
                  
                />
                {error.terms && <Form.Text className="text-danger">{error.terms}</Form.Text>}
              </Form.Group>

              {error.general && <p className="text-danger">{error.general}</p>} {/* Display general error message */}

              <Button 
                variant="primary" 
                type="submit" 
                disabled={loading} // Disable button while loading
                className="w-100" // Full-width button
              >
                {loading ? (
                  <Spinner 
                    animation="border" 
                    size="sm" 
                    role="status" 
                    aria-hidden="true" 
                    className="me-2"
                  />
                ) : (
                  'Continuar'
                )}
                {loading ? ' Enviando...' : ''}
              </Button>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ServiceModal;
