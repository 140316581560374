import '../App.css';
import logoMain from '../img/inmoapp_logo.png';
import React, { useState  } from "react";
import axios from 'axios';

function Admin() {
    const [user, setUser] = useState('');
    const [pw, setPw] = useState('');
    const [logs, setLogs] = useState();

    const signin = () => {
        axios({
            method:'POST',
            url: process.env.REACT_APP_API_URL+':8099/api/admin_logs',
            data:{
              user:user,
              pw:pw
            }
        }).then(res =>  {
            setLogs(res.data);
        }).catch(error => {
            console.log(error);
          alert("Hubo un error. Por favor revise los datos ingresados.");
        });
    }
    
    return (
        <div>
          {!logs ? (
            <div className="login-admin" id="">
              <img src={logoMain} style={{ width: '300px', height: '100px' }} alt="Logo" />
              <div className="input-login-admin"><input placeholder="Usuario" onChange={e => setUser(e.target.value)} /></div>
              <div className="input-login-admin"><input placeholder="Clave" type="password" onChange={e => setPw(e.target.value)} /></div>
              <div><button className="tasarBtn" onClick={signin}>Ingresar</button></div>
            </div>
          ) : (
            <div>{
                logs.map((data, index) => {
                    return(
                        <div className={'infoCard'}>
                            <span className={'tables'}>Propiedad: <b>{data.property}</b></span>
                            <span className={'tables'}>Ambientes: <b>{data.amb}</b></span>
                            <span className={'tables'}>Cochera: <b>{data.garage}</b></span>
                            <span className={'tables'}>M2 Cubiertos: <b>{data.sc}</b></span>
                            <span className={'tables'}>M2 Semi: <b>{data.st}</b></span>
                            <span className={'tables'}>M2 Descubierto: <b>{data.sd}</b></span>
                            <span className={'tables'}>Estado: <b>{data.condition}</b></span>
                            <span className={'tables'}>Antiguedad: <b>{data.antiguedad}</b></span>
                            <span className={'tables'}>M2 Descubierto: <b>{data.sd}</b></span>
                        </div>
                    )
                }) 
            }</div>
          )}
        </div>
      );
  
}

export default Admin;
