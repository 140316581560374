import React from 'react';

// Componente de Términos y Condiciones
const TermsAndConditions = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    sectionTitle: {
      fontSize: '24px',
      marginBottom: '10px',
      color: '#333',
    },
    list: {
      marginLeft: '20px',
      listStyleType: 'disc',
    },
    listItem: {
      marginBottom: '10px',
    },
    footer: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '14px',
      color: '#555',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1>Términos y Condiciones de Uso</h1>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Introducción</h2>
        <p>
          Bienvenido a <strong>Tasacionesya.com</strong>. Estos Términos y Condiciones rigen el uso de nuestro sitio web, operado por <strong>Sr Realty Group S.A.S.</strong>. Al acceder y utilizar este sitio web, aceptas cumplir y estar sujeto a estos Términos y Condiciones. Si no estás de acuerdo con estos términos, por favor, no utilices nuestro sitio web.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>2. Aceptación de los Términos</h2>
        <p>
          Tu aceptación de estos Términos y Condiciones se considera implícita al utilizar nuestro sitio web. Nos reservamos el derecho de modificar estos términos en cualquier momento. Notificaremos a los usuarios registrados sobre cualquier cambio importante mediante correo electrónico.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Uso del Sitio Web</h2>
        <h3>3.1 Licencia de Uso</h3>
        <p>
          Se concede una licencia limitada, no exclusiva, intransferible y revocable para acceder y utilizar el sitio web con el propósito de buscar información sobre nuestros servicios. Todo el contenido del sitio es de dominio público.
        </p>
        <h3>3.2 Prohibiciones</h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>Actividades indebidas o ilegales.</li>
          <li style={styles.listItem}>Violaciones de derechos de autor o propiedad intelectual.</li>
          <li style={styles.listItem}>Conductas que puedan dañar el funcionamiento del sitio o la experiencia de otros usuarios.</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>4. Registro de Usuario</h2>
        <h3>4.1 Información Requerida</h3>
        <p>
          Para acceder a ciertas funciones, puedes necesitar proporcionar:
          <ul style={styles.list}>
            <li style={styles.listItem}>Nombre</li>
            <li style={styles.listItem}>Apellido</li>
            <li style={styles.listItem}>Correo electrónico</li>
            <li style={styles.listItem}>Nombre de la empresa (si aplica)</li>
            <li style={styles.listItem}>Teléfono</li>
          </ul>
        </p>
        <h3>4.2 Responsabilidad</h3>
        <p>
          Eres responsable de la exactitud de la información proporcionada y de la seguridad de tus credenciales de acceso. La seguridad de tu cuenta es tu responsabilidad.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>5. Propiedad Intelectual</h2>
        <p>
          Todo el contenido del sitio, incluyendo texto, gráficos, logotipos y software, está protegido por derechos de autor y es propiedad de <strong>Tasacionesya.com</strong>. No se permite la reproducción, distribución o modificación sin autorización expresa.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>6. Contenido del Usuario</h2>
        <h3>6.1 Moderación</h3>
        <p>
          Nos reservamos el derecho de moderar y eliminar cualquier contenido que consideremos inapropiado. Los usuarios que publiquen contenido prohibido pueden ser expulsados de la plataforma.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>7. Política de Privacidad</h2>
        <h3>7.1 Recopilación de Datos</h3>
        <p>
          Recopilamos todos los datos proporcionados por los usuarios, incluyendo información personal y de contacto.
        </p>
        <h3>7.2 Uso de los Datos</h3>
        <p>
          Los datos recopilados se utilizarán para fines de contacto, marketing y envío de boletines informativos (newsletter).
        </p>
        <h3>7.3 Protección de Datos</h3>
        <p>
          Los datos se almacenan en una base de datos privada y segura. Implementamos medidas razonables para proteger la información de acceso no autorizado.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>8. Compra y Pagos</h2>
        <h3>8.1 Proceso de Compra</h3>
        <p>
          Utilizamos <strong>MercadoPago</strong> como herramienta de pago. Los precios pueden variar y se indican en el momento de la compra.
        </p>
        <h3>8.2 Política de Reembolsos</h3>
        <p>
          Ofrecemos reembolsos y cancelaciones si el cliente no está conforme con el servicio, siempre que el cliente no haya utilizado el producto o servicio adquirido.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>9. Limitación de Responsabilidad</h2>
        <h3>9.1 Exclusión de Garantías</h3>
        <p>
          La plataforma se proporciona "tal cual" y no garantiza que el resultado será el esperado por el usuario. No somos responsables de las expectativas del usuario.
        </p>
        <h3>9.2 Responsabilidad por Daños</h3>
        <p>
          No nos hacemos responsables de ningún daño o pérdida derivada del uso del sitio web.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>10. Enlaces a Terceros</h2>
        <p>
          El sitio puede contener enlaces a sitios web de terceros. No asumimos responsabilidad por las políticas o prácticas de esos sitios.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>11. Resolución de Disputas</h2>
        <h3>11.1 Ley Aplicable</h3>
        <p>
          Estos Términos y Condiciones se regirán por las leyes de <strong>Argentina</strong>.
        </p>
        <h3>11.2 Método de Resolución</h3>
        <p>
          Cualquier disputa se resolverá según las prácticas comunes en Argentina.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>12. Disposiciones Finales</h2>
        <h3>12.1 Divisibilidad</h3>
        <p>
          Si alguna disposición de estos términos es considerada inválida o inaplicable, el resto de los términos seguirán siendo aplicables.
        </p>
        <h3>12.2 Acuerdo Completo</h3>
        <p>
          Estos Términos y Condiciones constituyen el acuerdo completo entre las partes en relación con el uso del sitio web.
        </p>
      </div>

      <div style={styles.footer}>
        <p>Fecha de última actualización: <strong>1 de julio de [Año]</strong></p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
